import { ConnectButton } from '@rainbow-me/rainbowkit'
import bg from './imgs/back.gif'
import fg from './imgs/land.jpg'

import dd from './imgs/DERP_TOKEN.png'
import React from 'react'
import Web3 from 'web3'
import event_bg from './imgs/swbg.gif'
import item1 from './imgs/light-robe.png'
import item1icon from './imgs/light-robe-icon.png' 

import item2 from './imgs/dark-robe.png'
import item2icon from './imgs/dark-robe-icon.png'
import item3 from './imgs/boba-helmet.png'
import item3icon from './imgs/boba-helmet-icon.png'

import item4 from './imgs/r2d2.png' 
import item4icon from './imgs/r2d2icon.png' 
import item5 from  './imgs/saber-green-plain.png'
import item5icon from './imgs/saber-icon.png'

import {
	useAccount,
	useChainId,
    usePrepareTransactionRequest,
    useSendTransaction,
    useWaitForTransactionReceipt,
} from 'wagmi'

import { config } from './config'

// import { whitelist } from './whitelist'


const web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.soniclabs.com'));



export function Web3App (props) {
	const [cost, setCost] = React.useState(100)  
	const [pending, setIsPending] = React.useState(false)
	const [pendingText, setPendingText] = React.useState("Transaction is in process") 
	const { address, isConnected } = useAccount()
	const chainId  = useChainId()

	const [networkConfig, setNetworkConfig] = React.useState(config["mainnet"])

	
const [style, setStyle] = React.useState({backgroundImage: `url(${fg})`})

  React.useEffect(
  	() => {
  		
			if (isConnected) {
				setStyle({backgroundImage: `url(${bg})`})
				getBal()
			} else {
				setStyle({backgroundImage: `url(${fg})`})
			}
			
  			
  		
  	},
  	[isConnected]
  	)



	const derps = new web3.eth.Contract(networkConfig["derps"]["abi"], networkConfig["derps"]["address"])

const [bal, setBal] = React.useState(0)
  const getBal = () => {
  	if (isConnected) {
  		derps.methods.balanceOf(address)
  		.call({from: address})
  		.then((balance)=>{
  			setBal(balance)
  		})
  	} else {
  		console.log("balance call failed")
  	}
  	
  }

	React.useEffect(()=>{
		
		getBal()

	},[networkConfig])


	const [activeIndex, setActiveIndex] = React.useState(999)


	React.useEffect(()=>{
		if (Number(bal) > 0) {
			setActiveIndex(0)
		}
	}, [bal])

	const next = () => {
		if (activeIndex < Number(bal) - 1 && activeIndex != 999) {
			setActiveIndex(activeIndex + 1)
		}
	}

	const prev = () => {
		if (activeIndex > 0 && activeIndex != 999) {
			setActiveIndex(activeIndex - 1)
		}
	}

	const [activeID, setActiveID] = React.useState(2001)
	const getTokenIdByIndex = () => {
		if (activeIndex < 999) {
			derps.methods.tokenOfOwnerByIndex(address, activeIndex)
				.call({from: address})
				.then((id)=>{
					setActiveID(id)
				})
			}
		
	}

	React.useEffect(()=>{
		getTokenIdByIndex()
	},[activeIndex])

	const [activeImage, setActiveImage] = React.useState("")
	const getMetadata = () => {
		if (activeID < 2001) {
			fetch("https://ipfs.io/ipfs/bafybeigvx3vsvpjrakn6tc5xl7ylcv2mlm45kwupt3yagpr5ekro66pe4y/" + activeID + ".json")
				.then((response)=>{
					console.log(response)
					response.json()
						.then((json) =>{
							console.log(json.image)
							setActiveImage(json.image.replace("ipfs://", "https://ipfs.io/ipfs/"))
						})
				})
		}
		
	}


	React.useEffect(()=>{
		getMetadata()

	},[activeID])

  
  
const [isEvent, setIsEvent] = React.useState(false)

React.useEffect(()=>{
	if (isEvent) {
		setStyle({backgroundImage: `url(${event_bg})`})
	} else {
		if (isConnected) {
			setStyle({backgroundImage: `url(${bg})`})
		} 
		
	}
},[isEvent])


const toggleEvent = () => {
	(isEvent) ? setIsEvent(false) : setIsEvent(true)
}

const [isItem1, setIsItem1] = React.useState(false)
const [isItem2, setIsItem2] = React.useState(false)
const [isItem3, setIsItem3] = React.useState(false)
const [isItem4, setIsItem4] = React.useState(false)
const [isItem5, setIsItem5] = React.useState(false)

const doNothing = () => {

}
const toggleItem1 = () => {

	if (isItem1) {
		setIsItem1(false)
		if ( !isItem2 && !isItem3 && !isItem4  && !isItem5) {
			toggleEvent()
		}
	} else {
		setIsItem1(true)
		if (!isItem2 && !isItem3 && !isItem4 && !isItem5) {
			toggleEvent()
		}
	}

}

const toggleItem2 = () => {

	if (isItem2) {
		setIsItem2(false)
		if ( !isItem1 && !isItem3 && !isItem4 && !isItem5) {
			toggleEvent()
		}
	} else {
		setIsItem2(true)
		if ( !isItem1 && !isItem3 && !isItem4 && !isItem5) {
			toggleEvent()
		}
	}

}

const toggleItem3 = () => {

	if (isItem3) {
		setIsItem3(false)
		if ( !isItem1 && !isItem2 && !isItem4 && !isItem5) {
			toggleEvent()
		}
	} else {
		setIsItem3(true)
		if (!isItem1 && !isItem2 && !isItem4 && !isItem5) {
			toggleEvent()
		}
	}

}

const toggleItem4 = () => {

	if (isItem4) {
		setIsItem4(false)
		if ( !isItem1 && !isItem2 && !isItem3 && !isItem5) {
			toggleEvent()
		}
	} else {
		setIsItem4(true)
		if (!isItem1 && !isItem2 && !isItem3 && !isItem5) {
			toggleEvent()
		}
	}

}
const toggleItem5 = () => {

	if (isItem5) {
		setIsItem5(false)
		if ( !isItem1 && !isItem2 && !isItem3 && !isItem4) {
			toggleEvent()
		}
	} else {
		setIsItem5(true)
		if (!isItem1 && !isItem2 && !isItem3 && !isItem4) {
			toggleEvent()
		}
	}

}


  return (
  	<div className={"App App--" + isConnected + " App-chain--" + chainId + " App-christmas--" + isEvent}>
	  	<div className="bg" style={style}>
	  		
	  		<div className="mint-box">

	  			<h2 className="derp-number">Derp #{activeID}</h2>
	  			<div className="mint-gif">
	  				<img src={activeImage} />
	  				<img className={"item1 item1--" + isItem1} src={item1} />
	  				{
	  					//	<img className={"item2 item2--" + isItem2} src={item2} />
	  				}
	  				
	  				<img className={"item3 item3--" + isItem3} src={item3} />
	  				
	  					 <img className={"item4 item4--" + isItem4} src={item4} />
	  				
	  				
	  				<img className={"item5 item5--" + isItem5} src={item5} />
	  				
	  			</div>
	  			<div className="change-nft">
	  				<button onClick={prev}>back</button>
	  				<span>{activeIndex}</span>
	  				<button onClick={next}>next</button>
	  			</div>
	  			
		  		<div className="toggles">
	  				<div onClick={toggleItem1} className={"event-toggle event-toggle--"+isEvent}><img src={item1icon} /></div>
	  				{
	  				//	<div onClick={toggleItem2} className={"event-toggle event-toggle--"+isEvent}><img src={item2icon} /></div>
	  			}
	  				<div onClick={toggleItem3} className={"event-toggle event-toggle--"+isEvent}><img src={item3icon} /></div>
	  				
	  				<div onClick={toggleItem4} className={"event-toggle event-toggle--"+isEvent}><img src={item4icon} /></div>
	  				
	  				
	  				<div onClick={toggleItem5} className={"event-toggle event-toggle--"+isEvent}><img src={item5icon} /></div>
	  				
	  			</div>
	  			
	  		</div>
	  			
	  		
	  		<p className="bal">You have {bal} derps</p>
	  		
	  
	  		<div className="dd"><img src={dd}/></div>
	  		<div className="connect-button">
	  			<div className="inliner docs"><a href="https://docs.derpe.xyz" target="_blank">DOCS</a></div>
	  			<div className="inliner"><ConnectButton /></div>
	  		</div>

	  		
	  		{
	  			/*
	  			<div className="addys">whitelist.map((addy,i)=>(
	  				<p>{web3.utils.toChecksumAddress(addy)}</p>

	  				))

	  				</div>*/

	  		}

			

	  		
	  	</div>
	 
  	</div>
  	)
};