export const config = {
	"mainnet": {
		"derps": {
				"abi": require("./abis/derps.json"),
			"address": "0x8500d84b203775FC8B418148223872b35c43B050"
		
		},
		"derp": {
			"abi": "",
			"address": ""
		},
		"derper": {
			"abi": "",
			"address": ""
		},
		"treasury": {
			"address": "0x68705637c16bF32A28a82914f7aADEFB3aBE31CD"
		},

	},
	"testnet": {
		"derps": {
			"abi": require("./abis/derps.json"),
			"address": "0x6c2f137932c9da8b022226e119f834Bd9cc58796"
		},
		"derp": {
			"abi": require("./abis/derp.json"),
			"address": "0x5fc63611E508879dDD5bdde0782Df0f2E543B172"
		},
		"derper": {
			"abi": require("./abis/derper.json"),
			"address": "0x83af58c22102078c8686501e018a26aAE15fc2cc"
		},
		"treasury": {
			"address": "0xe02237ac085146389e8aCA2E501394bBa75A58e8"
		},
	}
}
	